import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})

export class AuthService {
    private authBaseURL = environment.apiURL  + '/auth';
    userData: any;

    constructor(
        public router: Router,
        public ngZone: NgZone,
        private http: HttpClient,
        private spinner: NgxSpinnerService
    ) {
        this.userData = JSON.parse(localStorage.getItem('user'));
    }

    public async signIn(email: string, password: string) {
        this.spinner.show();

        try {
            const res: any = await (this.http.post(this.authBaseURL + '/email/admin/login', {
                email, password
            }).toPromise());
            this.userData = {
                ...res.user,
                token: res.token
            };
            localStorage.setItem('user', JSON.stringify(this.userData));
            this.router.navigate(['/pages/dashboard']);
        } catch(e) {
            Swal.fire(
              'Failed!',
              'There was problem with your account, or it is already enrolled',
              'error'
            );
        }
        this.spinner.hide();
    }

    public async setPassword(password: string, id: string) {
        this.spinner.show();
        let message = null;

        try {
            const res: any = await (this.http.post(this.authBaseURL + `/reset/password`, {
                id,
                password
            }).toPromise());
            this.userData = {
                ...res.user,
                token: res.token
            };
            localStorage.setItem('user', JSON.stringify(this.userData));
            this.router.navigate(['/pages/dashboard']);
        } catch(e) {
            message = e;
        }
        this.spinner.hide();
        return message;
    }

    public signOut() {
        localStorage.setItem('user', null);
        this.router.navigate(['/auth/login']);
    }

    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user !== null && user.emailVerified !== false) ? true : false;
    }
}
