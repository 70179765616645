/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DefaultEditor } from 'ng2-smart-table';
import { Observable } from 'rxjs';

@Component({
  selector: 'ngx-file-upload',
  template: `
    <label [for]="generatedId" class="custom-file-upload">
      <input
        [id]="generatedId"
        type="file"
        accept=".svg"
        (change)="handleFileInput($event)"
      />
      {{ fileToUpload ? 'File selected' : 'Select file'}}
    </label>
  `,
  styles: [`
  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
  }
  `]
})
export class FileUploadComponent extends DefaultEditor implements OnInit {
  @Input() selected: string = '';
  @Input() onChange = (a) => {};
  @Input() value: string | number;
  @Input() rowData: any;
  @Output() save: EventEmitter<any> = new EventEmitter();

  fileToUpload: File | null = null;
  generatedId: string;

  uuidv4() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  ngOnInit(): void {
    this.generatedId = this.uuidv4();
  }

  handleFileInput(event: any) {
    this.fileToUpload = event.target.files.item(0);
    this.cell.newValue = this.fileToUpload
  }
}